// Generated by Framer (1119174)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/i59KN99_1";
import CTA from "./OlTWqYMo3";
const CTAFonts = getFonts(CTA);

const cycleOrder = ["PzbppBerv"];

const serializationHash = "framer-xwbQM"

const variantClassNames = {PzbppBerv: "framer-v-11ibhha"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({background, cTA, height, id, link, title, width, ...props}) => { return {...props, mmjEhMT7M: background ?? props.mmjEhMT7M ?? "var(--token-2e606580-c5cb-45c2-a66e-07cb8dbe5a38, rgb(116, 33, 252)) /* {\"name\":\"Purple\"} */", P5ypLIzaB: link ?? props.P5ypLIzaB, PK24BNG8p: title ?? props.PK24BNG8p ?? "Explore brand new Boost template.", uJaM6kO_P: cTA ?? props.uJaM6kO_P ?? "Learn more"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;background?: string;cTA?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, PK24BNG8p, mmjEhMT7M, uJaM6kO_P, P5ypLIzaB, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "PzbppBerv", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-xwbQM", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-11ibhha", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"PzbppBerv"} ref={ref ?? ref1} style={{backgroundColor: mmjEhMT7M, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-qemwmo"} data-styles-preset={"i59KN99_1"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-8c47652b-dea5-4767-a9f2-5d952dcce49a, rgb(255, 255, 255)))"}}>Explore brand new Fraction template.</motion.p></React.Fragment>} className={"framer-1qqdo7e"} layoutDependency={layoutDependency} layoutId={"j9huOSKKo"} style={{"--extracted-r6o4lv": "var(--token-8c47652b-dea5-4767-a9f2-5d952dcce49a, rgb(255, 255, 255))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={PK24BNG8p} verticalAlignment={"top"} withExternalLayout/><motion.div className={"framer-1h6q6rk-container"} layoutDependency={layoutDependency} layoutId={"MyhLg6pNV-container"}><CTA EnBVDC6a2={"var(--token-8c47652b-dea5-4767-a9f2-5d952dcce49a, rgb(255, 255, 255)) /* {\"name\":\"White\"} */"} height={"100%"} id={"MyhLg6pNV"} layoutId={"MyhLg6pNV"} SRBP_E1AV={uJaM6kO_P} variant={"QKYatfjMV"} width={"100%"} zNGAVVmSy={P5ypLIzaB}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-xwbQM [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-xwbQM .framer-efgtnd { display: block; }", ".framer-xwbQM .framer-11ibhha { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 6px; height: 60px; justify-content: center; overflow: hidden; padding: 25px 25px 25px 25px; position: relative; width: 1200px; }", ".framer-xwbQM .framer-1qqdo7e { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-xwbQM .framer-1h6q6rk-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-xwbQM .framer-11ibhha { gap: 0px; } .framer-xwbQM .framer-11ibhha > * { margin: 0px; margin-left: calc(6px / 2); margin-right: calc(6px / 2); } .framer-xwbQM .framer-11ibhha > :first-child { margin-left: 0px; } .framer-xwbQM .framer-11ibhha > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 60
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"PK24BNG8p":"title","mmjEhMT7M":"background","uJaM6kO_P":"cTA","P5ypLIzaB":"link"}
 * @framerImmutableVariables true
 */
const FramerKRmClVUI2: React.ComponentType<Props> = withCSS(Component, css, "framer-xwbQM") as typeof Component;
export default FramerKRmClVUI2;

FramerKRmClVUI2.displayName = "Banner";

FramerKRmClVUI2.defaultProps = {height: 60, width: 1200};

addPropertyControls(FramerKRmClVUI2, {PK24BNG8p: {defaultValue: "Explore brand new Boost template.", displayTextArea: false, title: "Title", type: ControlType.String}, mmjEhMT7M: {defaultValue: "var(--token-2e606580-c5cb-45c2-a66e-07cb8dbe5a38, rgb(116, 33, 252)) /* {\"name\":\"Purple\"} */", title: "Background", type: ControlType.Color}, uJaM6kO_P: {defaultValue: "Learn more", displayTextArea: false, title: "CTA", type: ControlType.String}, P5ypLIzaB: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerKRmClVUI2, [...CTAFonts, ...sharedStyle.fonts])